<template>
  <div>
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndUp ? 'py-10' : ''">
        <!-- <video-player :src="src"></video-player> -->
        <video
          @click="openVideo"
          style="cursor: pointer"
          :width="$vuetify.breakpoint.smAndUp ? '90%' : '100%'"
          height="100%"
          class="IntroVideo"
          poster="@/assets/publicDonationPage/timelineEvent/intro.jpg"
        >
          <source :src="getVideo" />
        </video>

        <img
          src="@/assets/play.png"
          alt=""
          :width="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          :height="$vuetify.breakpoint.smAndUp ? '70' : '50'"
          @click="openVideo"
          :style="
            $vuetify.breakpoint.smAndUp
              ? 'position: relative; bottom: 40%; right: 45%; cursor: pointer'
              : 'position:relative;bottom:60%;left:48%'
          "
        />
      </v-col>
    </v-row>
    <full-screen-video-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-video-viewer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "IntroVideoPdp",
  components: {
    FullScreenVideoViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      imageViewer: {
        src: "",
        flag: false,
        type: "video",
      },
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getVideo() {
      return this.getCampaign.campaign_detail.introduction_video;
    },
  },
  watch: {},
  methods: {
    openVideo() {
      console.log("video opened");
      this.imageViewer.src = this.getVideo;
      this.imageViewer.flag = true;
    },
  },
  mounted() {},
};
</script>
